







import { Component, Vue, Watch } from 'vue-property-decorator'
import LaserFocus from '@/components/LaserFocus.vue'
import routes from '@/routes'
import onBeforeUnload from '@/util/onBeforeUnload'

@Component({
  components: {
    LaserFocus,
  },
})
export default class AddMedications extends Vue {
  currentStepIndex = 0
  stepCount = 0
  unsubscribe: Function = () => {}

  mounted() {
    this.calculateProgress()
    this.unsubscribe = onBeforeUnload(() =>
      this.formStarted ? this.$t('updateDoctor.cancel.body') : true,
    )
  }

  beforeDestroy() {
    this.unsubscribe()
    this.$store.commit('physicianUpdate/reset')
  }

  @Watch('$route')
  onRouteChange(): void {
    this.calculateProgress()
  }

  get completion(): number {
    if (!this.stepCount) return 0
    return ((this.currentStepIndex + 1) / this.stepCount) * 100
  }

  get formStarted() {
    return this.currentStepIndex > 0
  }

  calculateProgress(): void {
    const steps = routes.find(route => route.name === this.$route.matched[0].name)!.children
    if (!steps) return
    const currentStep = steps.find(route => {
      return [this.$route.name, this.$route.matched[1].name].includes(route.name)
    })
    if (!currentStep) return
    this.stepCount = steps.length
    this.currentStepIndex = steps.indexOf(currentStep)
  }

  beforeUnloadHandler(e: BeforeUnloadEvent) {
    if (this.formStarted) {
      const confirmationMessage = this.$t('updateDoctor.cancel.body') as string
      e.returnValue = confirmationMessage // Gecko + IE
      return confirmationMessage // Gecko + Webkit, Safari, Chrome etc.
    }
    return true
  }

  async close() {
    if (this.formStarted) {
      const confirmed = await this.$bvModal.msgBoxConfirm(
        this.$t('updateDoctor.cancel.body') as string,
        {
          title: this.$t('updateDoctor.cancel.header') as string,
          id: 'confirm-navigation',
          okVariant: 'outline-danger',
          okTitle: this.$t('updateDoctor.cancel.leave') as string,
          cancelTitle: this.$t('updateDoctor.cancel.stay') as string,
        },
      )

      if (!confirmed) return
    }
    this.$router.push({
      name: 'medication-details',
    })
  }
}
