























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class LaserFocus extends Vue {
  @Prop({ type: Boolean, default: false })
  back?: boolean

  destroyed(): void {
    this.$emit('hidden')
  }
}
