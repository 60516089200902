import { TranslateResult } from 'vue-i18n'

export default function onBeforeUnload(listener: (e: BeforeUnloadEvent) => true | TranslateResult) {
  const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
    const result = listener(e)
    if (result === true) return true
    e.returnValue = result // Gecko + IE
    return result // Gecko + Webkit, Safari, Chrome etc.
  }

  if (!window.Cypress) {
    // The unload dialog will break cypress tests
    window.addEventListener('beforeunload', beforeUnloadHandler)
  }

  return function unsubscribe() {
    if (!window.Cypress) {
      // The unload dialog will break cypress tests
      window.removeEventListener('beforeunload', beforeUnloadHandler)
    }
  }
}
